import React from 'react'
import Layout from '../templates/layout'
import CTAHome from '../components/CTAHome'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Helmet from 'react-helmet'

type Props = {
	data: any
	location: Location
}

const Home = ({ data, location }: Props) => {
	return (
	<>
	<Helmet>
    <body className="new-class-for-body" />
</Helmet>
		<Layout>
			<SEO
				SEOData={{
					title: 'EXKIVITY® (mobocertinib) | Discover Your Path Ahead',
					description:
						'Patient site for EXKIVITY® (mobocertinib) prescription medication to treat adults with EGFR Exon20 insertion-positive non-small cell lung cancer (NSCLC). Please see the Patient Information, Safety, and Boxed Warning.',
					path: location.pathname,
				}}
			/>
			<div className="homepage-banner flex justify-center relative min-h-screen bg-cover bg-[url('../images/home/hero-home-mobile.webp')] lg:bg-[url('../images/home/hero-home.webp')]">
				<div className='lg:absolute flex flex-col lg:w-9/12 justify-between lg:h-full'>
					<div className='mx-5 md:mx-0 mt-5 md:mt-32 md:self-end'>
						<div className='text-white md:mr-24 md:pl-3'>
							<p className='text-3xl md:text-6xl tracking-wider [text-shadow:10px_4px_10px_rgba(0,0,0,0.6)] font-black'>
								DISCOVER YOUR
							</p>
							<p className='text-3xl md:text-6xl tracking-wider [text-shadow:10px_4px_10px_rgba(0,0,0,0.6)] font-black'>
								PATH AHEAD
							</p>
							<p className='text-xl md:text-3xl tracking-wider [text-shadow:10px_4px_10px_rgba(0,0,0,0.6)] font-bold'>
								WITH ORAL EXKIVITY
							</p>
							<p className='bg-gradient-to-r from-pompadourViolet p-4 -ml-3 mt-3 text-xs md:text-base [text-shadow:10px_4px_10px_rgba(0,0,0,0.6)]'>
								FOR PATIENTS WITH{' '}
								<strong className='text-goldenFizzGreen'>EGFR EXON20 INSERTION</strong>
								–POSITIVE <br className='hidden lg:block' />
								METASTATIC NON-SMALL CELL LUNG CANCER (NSCLC){' '}
								<br className='hidden lg:block' />
								AFTER CHEMOTHERAPY THAT CONTAINS PLATINUM
							</p>
						</div>
					</div>
					<div className='mt-44 md:mt-0 just'>
						<div className='grid md:grid-cols-3 portrait:!grid-cols-1 gap-5 md:gap-2 w-full px-5 mx-auto mb-[5%]'>
							<CTAHome
								title='What Is EXKIVITY?'
								description='A medicine made for your type of lung cancer'
								icon={data.lungsIcon}
								actionText='Learn more'
								actionTo='/about-exkivity'
							/>
							<CTAHome
								title='Starting on EXKIVITY'
								description='Get tips for taking oral EXKIVITY at home'
								icon={data.pillIcon}
								actionText='Explore now'
								actionTo='/taking-exkivity'
							/>
							<CTAHome
								title='Resources to Help'
								description='Discover ways to manage your treatment journey'
								icon={data.searchIcon}
								actionText='Find resources'
								actionTo='/resources'
							/>
						</div>
					</div>
				</div>
			</div>
			
		</Layout>
		</>
	)
}

export default Home

export const query = graphql`
	{
		lungsIcon: file(relativePath: { eq: "home/lungs.png" }) {
			...CTAHomeIcon
		}
		pillIcon: file(relativePath: { eq: "home/pill.png" }) {
			...CTAHomeIcon
		}
		searchIcon: file(relativePath: { eq: "home/search.png" }) {
			...CTAHomeIcon
		}
	}
`
