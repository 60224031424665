import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

type Props = {
	title: string
	description: string
	actionText: string
	actionTo: string
	icon: any
}

const CTAHome = (props: Props) => {
	return (
		<Link
			to={props.actionTo}
			className="flex justify-between flex-col rounded-md px-2 md:px-3 pt-4 pb-3 bg-white bg-cover bg-[url('../images/cta-bg.png')] bg-[length:25%_auto] bg-left-bottom bg-no-repeat border-mediumVioletRed border"
		>
			<div className='flex'>
				<div className='mr-3 w-1/6 md:w-2/6 2xl:w-2/6'>
					<GatsbyImage
						image={props.icon.childImageSharp.gatsbyImageData}
						alt={props.title}
					/>
				</div>
				<div className='grow'>
					<h2 className='text-lg md:text-xl text-pompadourViolet font-bold'>
						{props.title}
					</h2>
					<p className='text-sm md:text-base'>{props.description}</p>
				</div>
			</div>
			<div className='md:mt-5 text-right'>
				<div className='text-mediumVioletRed font-bold text-sm md:text-base'>
					<span>{props.actionText}</span>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-4 w-4 inline-block'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
						strokeWidth={2}
					>
						<path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7' />
					</svg>
				</div>
			</div>
		</Link>
	)
}

export default CTAHome
